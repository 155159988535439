<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Pesquisar vendas no {{ nomeIntegracaoDaLoja(loja.integracaoId) }} - {{ loja.nome }}
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <div class="p-2">
            <label class="pb-1">* A pesquisa bling e navegar pela paginação tem um tempo de 1seg para cada envio por conta da api bling V3. Evite pesquisas com grande periodo de datas</label>
            <b-row>
              <b-col
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  v-model="dateRange"
                  name="date-range"
                >
                  <date-range :date-range.sync="dateRange"/>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="validationContext"
                  name="Situação"
                  :rules="{ required: false }"
                >
                  <b-form-group
                    label="Situação"
                    label-for="situacaoId"
                  >
                    <v-select
                      id="situacaoId"
                      v-model="idSituacao"
                      multiple
                      :options="situacaoOptions"
                      :reduce="val=>val.value"
                      :clearable="true"
                      placeholder="Todas"
                    />
                    <span class="undermessage-error">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Numero Pedido"
                  rules="noAlpha"
                >
                  <b-form-group
                    label="Numero Pedido"
                    label-for="numero-pedido"
                  >
                    <b-form-input
                      id="numero-pedido"
                      v-model="numero"
                      trim
                      placeholder="123"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
              >
                <div class="p-2">
                  <b-row class="flex-row-reverse">
                    <div style="display:flex; align-items: center; justify-content: center">
                      <b-button
                        variant="primary"
                        class="mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="submit"
                      >
                        Pesquisar
                        <feather-icon
                          class="ml-1"
                          icon="SearchIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="showResultados"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>

          <!-- Search -->
          <b-col
            v-if="loja.integracaoId == 1"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
                debounce="500"
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="changePerPage"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        show-empty
        :empty-text="carregando ? 'Carregando...' : 'Nenhum resultado encontrado.'"
        :items="vendas"
        :fields="loja.integracaoId == 1 ? tableColumns : tableColumnsV3"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        sticky-header
        @filtered="onFiltered"
      >
        <template #head(select)="{}">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllHandler"
            />
          </div>
        </template>
        <template #cell(select)="data">
          <div class="d-flex justify-content-center">
            <b-checkbox
              v-model="data.item.selected"
              :disabled="data.item.importado || 
                (data.item.transporte && data.item.transporte.volumes
                  && data.item.transporte.volumes[0] && data.item.transporte.volumes[0].volume
                  && data.item.transporte.volumes[0].volume.codigoRastreamento != null
                  && data.item.transporte.volumes[0].volume.codigoRastreamento.startsWith('18'))"
            />
          </div>
        </template>
        <template #cell(numeroNota)="data">
          <template v-if="data.item.nota">
            <b-badge
              :id="`tooltip-nf-target-${data.index}`"
              :class="`badge badge-light-${data.item.nota.chaveAcesso ? 'success' : 'danger'}`"
            >
              {{ data.item.nota.numero }}
            </b-badge>
            <b-tooltip 
              v-if="!data.item.nota.chaveAcesso"
              :target="`tooltip-nf-target-${data.index}`"
              triggers="hover"
            >
              <span>Sem chave de NF</span>
            </b-tooltip>
          </template>
        </template>
        <template #cell(dataPedido)="data">
          {{ data.item.data ? maskDate(data.item.data) : '' }}
        </template>
        <template #cell(data)="data">
          {{ data.item.data ? maskDate(data.item.data) : '' }}
        </template>
        <template #cell(situacao)="data">
          {{ data.item.situacao !== null ? ajusteSituacao(data.item.situacao) : '' }} 
        </template>
        <template #cell(dataEmissaoNotaFiscal)="data">
          {{ data.item.dataEmissaoNotaFiscal ? maskDate(data.item.dataEmissaoNotaFiscal) : '' }}
        </template>
        <template #cell(dataNota)="data">
          {{ data.item.nota && data.item.nota.dataEmissao ? maskDate(data.item.nota.dataEmissao) : '' }}
        </template>
        <template #cell(cep)="data">
          <template v-if="data.item.cliente && data.item.cliente.cep && data.item.cepInvalido != null">
            <b-badge
              :id="`tooltip-cep-target-${data.index}`"
              :class="`badge badge-light-${data.item.cepInvalido ? 'danger' : 'success'}`"
            >
              {{ data.item.cliente ? maskCep(data.item.cliente.cep).replace('.','') : "" }}
            </b-badge>
            <b-tooltip 
              v-if="data.item.cepInvalido"
              :target="`tooltip-cep-target-${data.index}`"
              triggers="hover"
            >
              <span>Cep Inválido</span>
            </b-tooltip>
          </template>
          <template 
            v-else
          >
            {{ data.item.cliente ? maskCep(data.item.cliente.cep).replace('.','') : '' }}
          </template>
        </template>
        <template #cell(importado)="data">
          <b-badge
            :class="`badge badge-light-${data.item.importado ? 'success' : 'danger'}`"
          >
            {{ data.item.importado ? 'SIM' : 'NÃO' }}
          </b-badge>
        </template>
        <template #cell(visualizar)="data">
          <b-button
            variant="link"
            @click="openModal(data)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
        </template>
      </b-table>
      <hr class="mt-0 mb-1">
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <validation-observer
        #default="{validate}"
        ref="gerarEtiquetasValidation"
      >
        <b-row class="p-2 d-flex pb-4 align-items-center">
          <b-col
            cols="3"
          >
            <span>
              {{ vendas && vendas.length > 0 ? vendas.filter(venda => venda.selected === true).length : 0 }} venda(s) selecionada(s)
            </span>
          </b-col>
          <b-col
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="Tipo Etiqueta"
              rules="required"
            >
              <b-form-group
                label="Tipo Etiqueta"
                label-for="tipoEtiquetaId"
                class="mt-1"
              >
                <v-select
                  id="tipoEtiquetaId"
                  v-model="tiposDeEtiquetas"
                  :options="tiposDeEtiquetasOptions"
                  :reduce="val=>val.value"
                  :clearable="true"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="Serviço"
              rules="required"
            >
              <b-form-group
                label="Serviço"
                label-for="servico-id"
                class="mt-1"
              >
                <v-select
                  id="servico-id"
                  v-model="servicoId"
                  :options="servicosOptions"
                  :reduce="val=>val.value"
                  :clearable="true"
                />
                <span class="undermessage-error">
                  {{ validationContext.errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="3"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="primary"
              @click="validate().then(imprimirEtiquetas)"
            >
              <span class="text-nowrap">Gerar e Imprimir Selecionados</span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 10000px"
      footer-class="d-flex justify-content-end"
    >
      <details-card :data="modalData" />
    </b-modal>
    <b-modal
      ref="modalEtiqueta"
      title="Impressão de Etiqueta"
      ok-only
      ok-title="OK"
      size="xl"
      class="iframe-modal"
      @hidden="atualizaTable"
    >
      <b-row>
        <b-col
          cols="12"
          :sm="getDeclaracaoConteudo || getDanfe ? 6 : 12"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrc"
            class="iframe"
          />
        </b-col>
        <b-col
          v-if="getDeclaracaoConteudo"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrcDeclaracaoConteudo"
            class="iframe"
          />
        </b-col>
        <b-col
          v-if="getDanfe"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrcDanfe"
            class="iframe"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import utils from '@/helpers/utils'
import swal from 'sweetalert2/dist/sweetalert2.min'
import DateRange from '@/components/form/DateRange.vue'
import { $themeConfig } from '@themeConfig'
import DetailsCard from '../components/details'

export default {
  name: 'VendasVue',
  components: { DetailsCard, DateRange },
  beforeMount() {
    const { lojaId } = this.$route.params
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))
    this.$store.dispatch('cliente/findById', clienteId)
    this.$store.dispatch('integracao/findAll')
    this.$store.dispatch('loja/findById', lojaId)
    this.$store.dispatch('servicos/findAll', clienteId)
      .then(servicos => this.$store.dispatch('servicos/setOptionsDefault', servicos))
  },
  setup() {
    const { appCarregando } = $themeConfig.app

    const clienteStore = createNamespacedHelpers('cliente')
    const integracaoStore = createNamespacedHelpers('integracao')
    const vendasStore = createNamespacedHelpers('vendas')
    const lojaStore = createNamespacedHelpers('loja')
    const servicoStore = createNamespacedHelpers('servicos')
    const gerarEtiquetaStore = createNamespacedHelpers('gerarEtiquetas')

    const showResultados = ref(false)
    const modal = ref(null)
    const modalEtiqueta = ref(null)
    const tableRef = ref(null)
    const gerarEtiquetasValidation = ref(null)

    let formatoEtiqueta = localStorage.getItem('formato-etiqueta')

    if (formatoEtiqueta === 'etiqueta_qr_code') {
      formatoEtiqueta = 'etiqueta_jadlog_16x10'
    }

    const state = reactive({
      cliente: clienteStore.useGetters(['cliente']).cliente,
      integracoes: integracaoStore.useGetters(['integracoes']).integracoes,
      volumesV3: integracaoStore.useGetters(['volumesV3']).volumesV3,
      vendas: vendasStore.useGetters(['vendas']).vendas,
      loja: lojaStore.useGetters(['loja']).loja,
      servicosOptions: servicoStore.useGetters(['servicosOptionsDefault']).servicosOptionsDefault,
      servicoId: null,
      dateRange: {
        startDate: new Date().toISOString().split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
      },
      movimentacoes: [],
      getEtiquetas: gerarEtiquetaStore.useGetters(['gerarEtiquetas']).gerarEtiquetas,
      getDeclaracaoConteudo: gerarEtiquetaStore.useGetters(['gerarDeclaracaoConteudo']).gerarDeclaracaoConteudo,
      getDanfe: gerarEtiquetaStore.useGetters(['gerarDanfe']).gerarDanfe,
      idSituacao: null,
      allSelected: false,
      modalData: {},
      modalEtiqueta: null,
      tiposDeEtiquetas: formatoEtiqueta || 'etiqueta_jadlog_16x10',
      movimentacoesId: [],
      pdfsrc: '',
      pdfsrcDeclaracaoConteudo: '',
      pdfsrcDanfe: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      carregando: false,
      numero: null,
    })

    watch(() => state.carregando, value => {
      if (value) {
        appCarregando.show()
      } else {
        appCarregando.hide()
      }
    })

    watch(() => state.vendas, value => {
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const nomeIntegracaoDaLoja = integracaoId => {
      const integ = state.integracoes.find(i => i.integracaoId === integracaoId)
      if (integ) {
        return integ.nome
      }
      return null
    }

    const sendRequest = obj => {
      state.allSelected = false
      state.carregando = true
      store.dispatch('vendas/findVendas', obj).then(dados => {
        state.carregando = false
        if (!dados.success) {
          swal.fire({
            icon: 'info',
            title: 'Atenção!',
            text: dados.body,
            showConfirmButton: true,
          })
        }
      })
    }

    const imprimirEtiquetasRequest = obj => store.dispatch('gerarEtiquetas/find', obj)
    const imprimirDeclaracaoConteudoRequest = obj => store.dispatch('gerarEtiquetas/declaracaoConteudo', obj)
    const imprimirDanfeRequest = obj => store.dispatch('gerarEtiquetas/danfe', obj)
    const openEtiquetaModal = data => {
      state.modalEtiqueta = data
      modalEtiqueta.value.show()
    }

    const table = {
      search: ref(''),
      sortBy: ref(''),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'select', sortable: false },
        { key: 'numero', label: 'Pedido', sortable: true },
        { key: 'dataPedido', sortable: true, label: 'Data Pedido', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'numeroNota', sortable: true, label: 'Nota Fiscal', tdClass: 'text-center', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'dataNota', sortable: true, label: 'Data NF', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'transporte.transportadora', sortable: true, label: 'Transportadora' },
        { key: 'shippmentId', sortable: true, label: 'ShippmentID' },
        { key: 'transporte.volumes[0].volume.codigoRastreamento', sortable: true, label: 'Rastreio' },
        { key: 'transporte.volumes[0].volume.servico', sortable: true, label: 'Serviço' },
        { key: 'cliente.nome', sortable: true, label: 'Destinatário' },
        { key: 'cep', sortable: true, label: 'Cep', tdClass: 'text-nowrap text-center' },
        { key: 'situacao', sortable: true, label: 'Situação', tdClass: 'text-nowrap' },
        { key: 'importado', tdClass: 'text-center', formatter: (_val, _key, item) => (item.importado ? 2 : 1), sortable: true, sortByFormatted: true },
        { key: 'visualizar', sortable: false },
      ],
      tableColumnsV3: [
        { key: 'select', sortable: false },
        { key: 'numero', label: 'Pedido', sortable: false },
        { key: 'data', sortable: false, label: 'Data Pedido', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'numeroNotaFiscal', sortable: false, label: 'Nota Fiscal', tdClass: 'text-center', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'dataEmissaoNotaFiscal', sortable: false, label: 'Data NF', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'nomeTransportador', sortable: false, label: 'Transportadora' },
        { key: 'shippmentId', sortable: false, label: 'ShippmentID' },
        { key: 'codigoRastreamento', sortable: false, label: 'Rastreio' },
        { key: 'servico', sortable: false, label: 'Serviço' },
        { key: 'nomeContato', sortable: false, label: 'Destinatário' },
        { key: 'cepContato', sortable: false, label: 'Cep', tdClass: 'text-nowrap text-center' },
        { key: 'situacao', sortable: false, label: 'Situação', tdClass: 'text-nowrap' },
        { key: 'importado', tdClass: 'text-center', formatter: (_val, _key, item) => (item.importado ? 2 : 1), sortable: false, sortByFormatted: true },
        { key: 'visualizar', sortable: false },
      ],
    }
    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const onSubmit = () => {
      table.search.value = ''
      const mountObject = {
        dataInicio: state.dateRange.startDate,
        dataFim: state.dateRange.endDate,
        idSituacao: state.idSituacao,
        apiKey: state.loja.token,
        integracaoId: state.loja.integracaoId,
        lojaId: state.loja.lojaId,
        clienteId,
        currentPage: state.currentPage,
        perPage: state.perPage,
        numero: state.numero,
      }
      sendRequest(mountObject)
      showResultados.value = true
    }

    watch(() => state.movimentacoes.filter(mov => mov.selected), value => {
      state.movimentacoesId = value.map(obj => obj.movimentacaoId)
    })

    const selectAllHandler = bool => {
      store.dispatch('vendas/selectAll', bool)
    }

    const montaObjEtiquetaV2 = pedido => {
      const cliente = {
        ...state.cliente,
      }
      const destinatario = {
        ...pedido.cliente,
      }
      const user = JSON.parse(localStorage.getItem('userName'))
      const volumes = pedido.transporte.volumes ? pedido.transporte.volumes.map((obj, index) => {
        const vol = {
          ...obj.volume.dimensoes,
          peso: Number(obj.volume.dimensoes.peso ? obj.volume.dimensoes.peso : 0) * 1000,
          formato: 'PACOTE',
          sequenciaVolume: (index + 1),
        }
        return vol
      }) : null
      const itens = pedido.itens ? pedido.itens.map((obj, index) => {
        const vol = {
          largura: Number(obj.item.largura ? obj.item.largura : '0'),
          altura: Number(obj.item.altura ? obj.item.altura : '0'),
          comprimento: Number(obj.item.profundidade ? obj.item.profundidade : '0'),
          peso: Number(obj.item.pesoBruto ? obj.item.pesoBruto : 0) * 1000,
          formato: 'PACOTE',
          sequenciaVolume: (index + 1),
        }
        return vol
      }) : null
      const etiqueta = {
        clienteId,
        clienteNome: state.cliente.nomeFantasia,
        servicoId: state.servicoId,
        numeroNotaFiscal: pedido.nota ? pedido.nota.numero : null,
        valorNotaFiscal: pedido.nota ? pedido.nota.valorNota : null,
        chaveNotaFiscal: pedido.nota ? pedido.nota.chaveAcesso : null,
        serieNotaFiscal: pedido.nota ? pedido.nota.serie : null,
        dataNotaFiscal: pedido.nota ? pedido.nota.dataEmissao : null,
        reversa: false,
        remetente: {
          clienteId,
          nome: cliente.nomeFantasia,
          nomeFantasia: cliente.nomeFantasia,
          tipoDocumento: cliente.tipoDocumento,
          numeroDocumento: cliente.numeroDocumento,
          email: cliente.email,
          telefone: cliente.telefone,
          cep: cliente.cep,
          uf: cliente.uf,
          cidade: cliente.cidade,
          bairro: cliente.bairro,
          logradouro: cliente.logradouro,
          numero: cliente.numero,
          complemento: cliente.complemento,
        },
        destinatario: {
          nome: destinatario.nome,
          // eslint-disable-next-line no-nested-ternary
          tipoDocumento: destinatario.cnpj ? (destinatario.cnpj.length === 11 ? 'CPF' : 'CNPJ') : null,
          numeroDocumento: destinatario.cnpj,
          email: destinatario.email,
          telefone: (destinatario.fone ? destinatario.fone.replaceAll(/[.,()/\s-]+/g, '') : null),
          celular: (destinatario.celular ? destinatario.celular.replaceAll(/[.,()/\s-]+/g, '') : null),
          cep: destinatario.cep,
          uf: destinatario.uf,
          cidade: destinatario.cidade,
          bairro: destinatario.bairro,
          logradouro: destinatario.endereco,
          numero: destinatario.numero,
          complemento: destinatario.complemento,
        },
        volumes: volumes || itens,
        quantidade: volumes ? volumes.length : 1,
        conteudo: [],
        nomeUsuarioGeracao: user.nome,
        numeroPedidoLoja: pedido.numero,
        metodoInsercao: `INTEGRACAO_${nomeIntegracaoDaLoja(state.loja.integracaoId).toUpperCase()}_${pedido.shippmentId != null ? 'LOJA' : 'PORTAL'}`,
        apikey: state.loja.token,
        numeroPedido: pedido.numero,
        idServicoPedido: pedido.transporte && pedido.transporte.volumes && pedido.transporte.volumes[0] ? pedido.transporte.volumes[0].volume.idServico : null,
        shippmentId: null,
      }
      return etiqueta
    }

    const ajusteServico = data => {
      const servicoV3 = data.replace('.', '')
      const servico = state.servicosOptions.find(s => s.label === servicoV3.toUpperCase())
      return servico.value
    }

    const tratarMensagem = (msgOriginal, msg) => {
      const msgErro = 'Dados Insuficientes: </br>'
      if (msgOriginal.length === 0) {
        return msgErro + msg
      } 
      return msgOriginal + msg
    }

    const montaObjEtiquetaV3 = pedido => {
      let msgErro = ''
      let cliente = null
      let destinatario = null
      let volumesParaBlingV3 = null
      if (state.cliente === null) {
        msgErro = tratarMensagem(msgErro, 'Cliente Incompleto </br>')
      } else {
        cliente = {
          ...state.cliente,
        }
      }

      if (pedido.detalhes === null) {
        msgErro = tratarMensagem(msgErro, 'Destinatario Incompleto </br> Nota Fiscal Incompleta </br>')
      } else {
        destinatario = {
          ...pedido.detalhes.cliente,
        }
      }
      const user = JSON.parse(localStorage.getItem('userName'))
      const { lojaId } = state.loja
      if (pedido.detalhes === null || pedido.detalhes.transporte === null) {
        msgErro = tratarMensagem(msgErro, 'Dados do transporte Incompleto </br>')
      } else {
        const { volumes } = pedido.detalhes.transporte
        volumesParaBlingV3 = volumes.map((v, index) => {
          const vol = {
            id: v.id,
            formato: 'PACOTE',
            sequenciaVolume: (index + 1),
          }
          return vol
        })
      }
      if (msgErro.length !== 0) {
        return { msgErro }
      } 
      const etiquetaMontada = {
        clienteId,
        clienteNome: state.cliente.nomeFantasia,
        servicoId: state.servicoId,
        numeroNotaFiscal: pedido.numeroNotaFiscal ? pedido.numeroNotaFiscal : null,
        valorNotaFiscal: pedido.detalhes.nota ? pedido.detalhes.nota.valorNota : null,
        chaveNotaFiscal: pedido.detalhes.nota ? pedido.detalhes.nota.chaveAcesso : null,
        serieNotaFiscal: pedido.detalhes.nota ? pedido.detalhes.nota.serie : null,
        dataNotaFiscal: pedido.detalhes.nota ? pedido.detalhes.nota.dataEmissao : null,
        reversa: false,
        remetente: {
          clienteId,
          nome: cliente.nomeFantasia,
          nomeFantasia: cliente.nomeFantasia,
          tipoDocumento: cliente.tipoDocumento,
          numeroDocumento: cliente.numeroDocumento,
          email: cliente.email,
          telefone: cliente.telefone,
          cep: cliente.cep,
          uf: cliente.uf,
          cidade: cliente.cidade,
          bairro: cliente.bairro,
          logradouro: cliente.logradouro,
          numero: cliente.numero,
          complemento: cliente.complemento,
        },
        destinatario: {
          nome: destinatario.nome,
          // eslint-disable-next-line no-nested-ternary
          tipoDocumento: destinatario.cnpj ? (destinatario.cnpj.length === 11 ? 'CPF' : 'CNPJ') : null,
          numeroDocumento: destinatario.cnpj,
          email: destinatario.email,
          telefone: (destinatario.fone ? destinatario.fone.replaceAll(/[.,()/\s-]+/g, '') : null),
          celular: (destinatario.celular ? destinatario.celular.replaceAll(/[.,()/\s-]+/g, '') : null),
          cep: destinatario.cep,
          uf: destinatario.uf,
          cidade: destinatario.cidade,
          bairro: destinatario.bairro,
          logradouro: destinatario.endereco,
          numero: destinatario.numero,
          complemento: destinatario.complemento,
        },
        volumes: volumesParaBlingV3,
        quantidade: volumesParaBlingV3 ? volumesParaBlingV3.length : 1,
        conteudo: [],
        nomeUsuarioGeracao: user.nome,
        numeroPedidoLoja: pedido.numero,
        metodoInsercao: `INTEGRACAO_${nomeIntegracaoDaLoja(state.loja.integracaoId).toUpperCase()}_${pedido.shippmentId != null ? 'LOJA' : 'PORTAL'}`,
        apikey: state.loja.token,
        numeroPedido: pedido.numero,
        idServicoPedido: pedido.servico ? ajusteServico(pedido.servico) : null,
        shippmentId: null,
        lojaId,
      }
      return { msgErro, etiquetaMontada }
    }

    const enviaRequisicoes = async pedidos => {
      state.movimentacoesId = []
      const { integracaoId } = state.loja
      let etiquetasNaoGeradas = 0
      const nfSemChave = []
      const cepsInvalidos = []
      const errosAleatorios = []
      const shippmentIdNaoEnviados = []
      let etiqueta
      // eslint-disable-next-line no-restricted-syntax
      for (const pedido of pedidos) {
        if (state.loja.integracaoId === 1) {
          etiqueta = montaObjEtiquetaV2(pedido)
        }
        if (state.loja.integracaoId === 2) {
          // eslint-disable-next-line no-loop-func, no-await-in-loop
          const { msgErro, etiquetaMontada } = montaObjEtiquetaV3(pedido)
          if (etiquetaMontada == null) {
            errosAleatorios.push(msgErro)
            etiquetasNaoGeradas += 1
          } else {
            etiqueta = etiquetaMontada
          }
        }
        if (etiqueta) {
          // eslint-disable-next-line no-loop-func, no-await-in-loop
          const dados = await store.dispatch('etiqueta/savePorIntegracao', { integracaoId, etiqueta })
          if (!dados.success) {
            etiquetasNaoGeradas += 1
            const erros = dados.body
            erros.forEach(erro => {
              if (erro.tipo && erro.tipo === 'NF_SEM_CHAVE') {
                nfSemChave.push(erro.objetoDoErro)
              } else if (erro.tipo && erro.tipo === 'CEP_INVALIDO') {
                cepsInvalidos.push(erro.objetoDoErro)
                pedido.cepInvalido = true
              } else {
                errosAleatorios.push(erro.message)
              }
            })
          } else {
            pedido.movimentacaoId = dados.body.movId
            pedido.shippmentId = dados.body.shippmentId
            pedido.shippmentIdEnviado = dados.body.shippmentIdEnviado
            pedido.importado = true
            pedido.cepInvalido = false
            pedido.selected = false
            state.movimentacoesId.push(dados.body.movId)
            if (dados.body.shippmentIdEnviado === false) {
              shippmentIdNaoEnviados.push(dados.body.shippmentId)
            }
          }
        }
      }
      return {
        etiquetasNaoGeradas,
        nfSemChave,
        cepsInvalidos,
        errosAleatorios,
        shippmentIdNaoEnviados,
      }
    }

    const atualizaTable = () => {
      tableRef.value.refresh()
    }

    const imprimirEtiquetas = () => {
      gerarEtiquetasValidation.value.validate().then(valido => {
        if (valido) {
          const pedidos = state.vendas.filter(venda => venda.selected)
          if (pedidos.length === 0) {
            swal.fire({
              icon: 'error',
              title: 'Algo deu errado',
              text: 'Selecione ao menos uma venda da lista',
              showConfirmButton: false,
              timer: 3000,
            })
          } else {
            appCarregando.show()
            enviaRequisicoes(pedidos).then(({ etiquetasNaoGeradas, nfSemChave, cepsInvalidos, errosAleatorios, shippmentIdNaoEnviados }) => {
              appCarregando.hide()
              atualizaTable()
              if (etiquetasNaoGeradas > 0) {
                swal.fire({
                  icon: 'error',
                  html: `<h3>Não foi possível gerar etiquetas de ${etiquetasNaoGeradas} venda(s)</h3>`
                  + '<div style="font-size:18px;">'
                  + `${nfSemChave.length > 0 ? `NF sem chave: ${nfSemChave.join(' / ')}` : ''}`
                  + `${cepsInvalidos.length > 0 ? `<br>CEP Inválido: ${cepsInvalidos.join(' / ')}` : ''}`
                  + `${errosAleatorios.length > 0 ? `<br>${errosAleatorios.join(' / ')}` : ''}`
                  + '</div>',
                  showConfirmButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => {
                  if (state.movimentacoesId.length > 0) {
                    const msg = `Código(s) de rastreio ${shippmentIdNaoEnviados.join(' / ')} <b>NÃO</b> atualizado(s)`
                    swal.fire({
                      icon: 'success',
                      title: 'ShippmentID gerado(s)',
                      html: shippmentIdNaoEnviados.length > 0 ? msg : 'Código(s) de rastreio atualizado(s), confira suas vendas no site da loja',
                      showConfirmButton: true,
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                    })
                  }
                })
              } else {
                const msg = `Código(s) de rastreio ${shippmentIdNaoEnviados.join(' / ')} <b>NÃO</b> atualizado(s)`
                swal.fire({
                  icon: 'success',
                  title: 'ShippmentID gerado(s)',
                  html: shippmentIdNaoEnviados.length > 0 ? msg : 'Código(s) de rastreio atualizados, confira suas vendas no site da loja',
                  showConfirmButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })
              }
              if (state.movimentacoesId.length > 0) {
                const sortedIds = tableRef.value.sortedItems.filter(item => state.movimentacoesId.includes(item.movimentacaoId)).map(ped => ped.movimentacaoId)
                const objEtiqueta = {
                  etiqueta: sortedIds,
                  tipoEtiqueta: state.tiposDeEtiquetas,
                  reversa: false,
                }
                const objDeclaracaoConteudo = {
                  etiqueta: sortedIds,
                  tipoEtiqueta: 'A4',
                  reversa: false,
                }
                if (objEtiqueta.etiqueta.length > 0 && objEtiqueta.tipoEtiqueta) {
                  localStorage.setItem('formato-etiqueta', state.tiposDeEtiquetas)
                  imprimirEtiquetasRequest(objEtiqueta)
                  imprimirDeclaracaoConteudoRequest(objDeclaracaoConteudo)
                  imprimirDanfeRequest(objEtiqueta)
                  openEtiquetaModal(state.modalEtiqueta)
                }
              }
            })
          }
        }
      }).catch(e => {
        appCarregando.hide()
        swal.fire({
          icon: 'success',
          title: 'ShippmentID gerado(s)',
          text: e,
          showConfirmButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
    }

    const ajusteSituacao = data => {
      if (data.toString().length < 3) {
        return utils.blingV3SituacaoDictionary(data)
      }
      return data
    }

    const openModal = data => {
      if (data.item.detalhes != null) {
        state.modalData = data.item.detalhes
        modal.value.show()
      } else if (data.item.nome != null) {
        state.modalData = data.item
        modal.value.show()
      } else {
        swal.fire({
          icon: 'error',
          title: 'Dados inconsistentes',
          text: 'Sem dados para apresentar nos detalhes, complete os dados no bling para velos nessa tela',
          showConfirmButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    watch(() => state.getEtiquetas, value => {
      state.pdfsrc = value
    })

    watch(() => state.getDeclaracaoConteudo, value => {
      state.pdfsrcDeclaracaoConteudo = value
    })

    watch(() => state.getDanfe, value => {
      state.pdfsrcDanfe = value
    })

    const situacaoOptions = [
      { value: '6', label: 'Em aberto' },
      { value: '9', label: 'Atendido' },
      { value: '12', label: 'Cancelado' },
      { value: '15', label: 'Em andamento' },
      { value: '18', label: 'Venda agenciada' },
      { value: '21', label: 'Em digitação' },
      { value: '24', label: 'Verificado' },
    ]

    const tiposDeEtiquetasOptions = [
      { value: 'etiqueta_jadlog_16x10', label: 'Etiqueta 16X10' },
      { value: 'etiqueta_jadlog_10x10', label: 'Etiqueta 10X10' },
      { value: 'etiqueta_jadlog_A4', label: 'Etiqueta 16x10 A4' },
      { value: 'danfe_simplificado_16x10', label: 'Etiqueta 16x10 com Danfe' },
      { value: 'etiqueta_jadlog_mini_danfe_16x10', label: 'Etiqueta 16x10 mais mini Danfe' },
    ]

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    const changePage = value => {
      state.currentPage = value
      onSubmit()
    }

    const changePerPage = () => {
      state.currentPage = 1
      onSubmit()
    }

    return {
      ...toRefs(state),
      ...utils,
      nomeIntegracaoDaLoja,
      situacaoOptions,
      tableRef,
      gerarEtiquetasValidation,
      changePage,
      changePerPage,
      ...table,
      selectAllHandler,
      modal,
      modalEtiqueta,
      openEtiquetaModal,
      openModal,
      imprimirEtiquetas,
      showResultados,
      onSubmit,
      tiposDeEtiquetasOptions,
      atualizaTable,
      ajusteSituacao,
      onFiltered,
    }
  },
}
</script>

<style lang="scss">
.iframe-modal {
  height: 100vh;
}

.iframe {
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
</style>
